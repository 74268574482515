import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import API from './webservices/api.js'

const app = createApp(App)
app.use(store).use(router).mount('#app')
// 全局配置
app.config.globalProperties.$api = API

// 使用element-ui
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
app.use(ElementPlus)

// 使用element-icon
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
