<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Georgia, serif;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
html,
body {
  min-width: 720px;
  max-width: 960px;
  margin: 0 auto;
  background: #ffffff;
  padding-bottom: 10px;
  font-size: 28px;
}
/* 全局变量 */
:root {
  /* 颜色 */
  --color-bg: #f0f0f0;
  --color-primary: #2593fc;

  /* 字体大小 */
  --font-title: 16px;
  --font-detail: 14px;
  --font-small: 12px;
}

</style>