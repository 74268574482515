import { createStore } from 'vuex'

export default createStore({
  state: {
    navIndex: -1 // 导航索引
  },
  mutations: {
    setNavIndex(state, payload) {
      state.navIndex = payload.navIndex
    }
  },
  actions: {
    switchNav({ commit }, navIndex) {
      commit('setNavIndex', { navIndex })
    }
  },
  modules: {
  }
})
