/**
 * 网络请求类
 * @author xxx
 * @date xxx
 */

import { request } from '@/webservices/base-api'

// 模块对应的接口
const API_MAP = {
  common: {
    getBanners: 'common/getBanners.do'
  }, // 公共模块接口
  user: {
    login: 'user/login.do'
  } // 用户模块接口
}

// 声明调用对象
// let API = {}
// ;
let API = {}
;

(function() {
  for (var k in API_MAP) {
    API[k] = {}
    let v = API_MAP[k]
    for (var subK in v) {
      let subV = v[subK]
      API[k][subK] = (function(path) {
        return function(params, method) {
          return request(path, params, method)
        }
      })(subV);
    }
  }
})();

// API['upload'] = function(filePath, params) {
//     return baseApi.upload('common/uploadFile.do', filePath, params)
// }
console.log(API)
// 声明使用
export default API
