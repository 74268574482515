<template>
  <section>
    <div class="banner" id="home">
      <!-- <p class="banner-title">首届“拼搏杯”羽毛球比赛圆满落幕</p> -->
    </div>

    <ul class="item-list">
      <li class="item" id="news">
        <div class="pic">
          <img src="@/assets/images/banner3.png" />
        </div>
        <div class="content">
          <div class="tag-title">
            <span>新闻中心</span>
          </div>
          <router-link to="/news/6" class="link"
            >中医在线问诊新时代 中裕康中医致力推动行业高质量发展</router-link
          >
          <router-link to="/news/5" class="link"
            >中裕康中医招募令！</router-link
          >
          <router-link to="/news/1" class="link"
            >2022年7月19日 中裕康首届“拼搏杯”羽毛球团建活动顺利进行</router-link
          >
          <router-link to="/news/2" class="link"
            >2022年6月13日 中裕康内部举办职业技能大比武</router-link
          >
          <router-link to="/news/3" class="link"
            >2022年6月04日 中裕康公司高层领导端午节慰问员工</router-link
          >
          <router-link to="/news/4" class="link"
            >2022年3月14日 中裕康春季秋季户外踏青健步走活动</router-link
          >
        </div>
      </li>
      <li class="item" id="group">
        <div class="content" style="padding-left: 87px">
          <div class="tag-title">
            <span>团队介绍</span>
          </div>
          <p>
            中裕康互联网医院总部位于大湾区中心东莞南城CBD，办公总面积近1000平方。中裕康互联网医院现有团队成员50多人，由产品技术、市场运营、医生及药师等不同团队组成，多数成员来自985、211等重点大学，平台研发团队经验丰富，技术实力强；平台已实现高度专业化、智能化，方便医患使用。
          </p>
        </div>

        <!-- <div class="pic">
          <img src="@/assets/images/group.png" />
        </div> -->
        <div class="team-introduction">
          <img
            src="@/assets/images/team-introduction1.png"
            alt=""
            class="introduction"
          />
          <img
            src="@/assets/images/team-introduction2.png"
            alt=""
            class="introduction"
          />
          <img
            src="@/assets/images/team-introduction3.png"
            alt=""
            class="introduction"
          />
          <img
            src="@/assets/images/team-introduction4.png"
            alt=""
            class="introduction"
          />
        </div>
      </li>
      <li class="item" id="qiye">
        <div class="pic">
          <img src="@/assets/images/qiye.png" />
        </div>
        <div class="content">
          <div class="tag-title">
            <span>企业文化</span>
          </div>
          <p>
            中裕康是一家年轻人汇集的高效率团队，公司的宗旨是“诚信、和谐、尽善、奉献、进取”，核心价值观是“用心奉献，精益求精，止于至善”，服务理念是“诚信为本，想客户之所想，急客户之所急，用心尽心实现客户的需求，随着更多年轻人的加入，中裕康将会提供更优质的服务。
          </p>
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 12px;
              margin-right: 80px;
              color: #8d785d;
              margin-top: 10px;
              font-weight: bold;
            "
          >
            <span>精选道地药材</span>
            <span>汇聚各地名医</span>
            <span>极速在线问诊</span>
            <span>弘扬中医文化</span>
          </div>
        </div>
      </li>
      <li class="item" id="huanjing">
        <div class="tag-title">
          <span>公司环境</span>
        </div>
        <div class="huanjing-pic">
          <img src="@/assets/images/huanjing1.png" />
          <img src="@/assets/images/huanjing2.png" />
          <img src="@/assets/images/huanjing3.png" />
        </div>
      </li>
    </ul>
  </section>
</template>

<style scoped>
.banner {
  display: flex;
  height: 490px;
  margin: 0 auto;
  background: url("~@/assets/images/banner2.png") no-repeat;
  background-size: 100% 100%;
}

.banner-title {
  margin: auto;
  font-size: 36.5px;
  font-weight: bold;
  color: #5c4220;
}

.item {
  display: flex;
  height: 390px;
  background-color: #ffffff;
}

.item:nth-child(2n) {
  background-color: #f8f3e8;
}

.pic {
  display: flex;
  width: 50%;
  height: 100%;
}

.pic img {
  width: 333px;
  height: auto;
  margin: auto;
}

.team-introduction {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  margin-left: 60px;
}
.introduction {
  width: 43%;
  height: auto;
  padding-right: 20px;
  box-sizing: content-box;
}
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
  padding-left: 20px;
}

.tag-title {
  width: 225px;
  height: 48px;
  line-height: 48px;
  background: url("~@/assets/images/tagbg.png") no-repeat;
  background-size: contain;
  text-align: center;
  color: #5c4220;
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 30px;
}

.item p,
.link {
  width: 360px;
  font-size: 11px;
  line-height: 34.13px;
  color: #8d785d;
  font-weight: bold;
}

#huanjing {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#huanjing .tag-title {
  margin: 0 auto;
  margin-bottom: 30px;
}

.huanjing-pic {
  display: flex;
  justify-content: center;
}

.huanjing-pic img {
  margin-left: 30px;
}
</style>
