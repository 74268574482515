import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '../pages/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: '/',
        component: () => import('@/pages/Home/HomeList')
      },
      {
        path: '/policy',
        component: () => import('@/pages/Policy/Policy')
      },
      {
        path: '/about',
        component: () => import('@/pages/About/About')
      },
      {
        path: '/jobs',
        component: () => import('@/pages/Jobs/Jobs')
      },
      {
        path: '/news/:id',
        component: () => import('@/pages/Home/News')
      }
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
