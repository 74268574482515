// 引入配置文件
import axios from "axios";
import qs from 'qs';
// const md5Util = require("@/ventors/md5/md5.js");

const IS_API_BETA = false; // 是否测试版，配置webpack来动态配置

const BASE_URL = IS_API_BETA ? "https://api-test.example.com/" : "https://api.example.com/";
const API_TOKEN = "";
const API_SIGN = "";

// 接口文档 https://

// axios配置信息
const axiosInstance = axios.create({
  baseURL: BASE_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});

// 加密参数
function encryptParams(params = {}) {
  // 植入token等信息
  params["token"] = API_TOKEN;
  params["timestamp"] = new Date().getTime();
  // 根据键值对先排序键
  var keys = Object.keys(params).sort();
  // 先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  var nParams = {}; // 创建一个新的对象，用于存放排好序的键值对
  var count = keys.length;
  for (var i = 0; i < count; i++) {
    // 遍历newkey数组
    var kk = keys[i];
    var val = params[kk];
    if (val === 0 || val === false || val) {
      nParams[kk] = val;
    }
  }
  // 加密签名
  var query = appendParams(nParams);
  var sign = query + API_SIGN;
  // var encryptSign = md5Util.md5(sign);
  // nParams["sign"] = encryptSign;
  // 返回排好序的新对象
  return nParams;
}

// 拼接参数
function appendParams(params = {}) {
  var keys = Object.keys(params).sort();
  var count = keys.length;
  var query = null;
  for (var i = 0; i < count; i++) {
    var kk = keys[i];
    var kv = kk + "=" + params[kk];
    if (query) {
      query += "&" + kv;
    } else {
      query = kv;
    }
  }
  return query;
}

/**
 * 发送网络请求
 * @param fn 请求地址
 * @param params 请求参数
 * @param method POST或GET方法
 */
export function request(fn, params = {}, method = "POST") {
  if (!fn) {
    return;
  }
  // 加密参数
  var nParams = encryptParams(params);
  // 请求数据
  return new Promise(function(resolve, reject) {
    console.log("=====>发送请求  " + fn);
    console.log(nParams);
    axiosInstance({
      url: fn,
      headers: {
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      params: method.toLowerCase() === "post" ? {} : nParams,
      data: method.toLowerCase() === "post" ? qs.stringify(nParams) : {},
      method: method,
    }).then((res) => {
      console.log("<=====数据返回  " + fn);
      console.log(res);
      var data = res.data;
      var status = data.status;
      var message = data.message;
      if (status == "success") {
        resolve(data.data ? data.data : data);
      } else if (status == "403") {
        // 返回
        reject({
          message,
          status,
        });
      } else {
        if (!message) {
          message = "未知错误";
        }
        reject({
          message,
          status,
        });
      }
    }).catch((err) => {
      console.log("<=====数据返回  " + fn);
      console.log(err);
      reject({
        message: err.errMsg,
        status: "error",
      });
    });
  });
}
