<template>
  <header>
    <img src="@/assets/images/logo.png" alt class="logo" />
    <ul class="nav-tab">
      <li v-for="(item, index) in navList" :key="id" @click="onClickNavHandler(index)">
        <router-link :to="item.path">{{item.name}}</router-link>
        <img :style="navIndex === index ? 'display: block' : ''" src="@/assets/images/xiahuaxian.png" alt />
      </li>
    </ul>
  </header>
</template>

<script>

import { mapState, mapActions } from 'vuex'  

export default {
  data() {
    return {
      navList: [
        {
          id: 1,
          path: '/',
          name: '首页'
        },
        {
          id: 2,
          path: '/',
          name: '新闻中心',
          anchor: 'news'
        },
        {
          id: 3,
          path: '/',
          name: '团队风采',
          anchor: 'group'
        },
        {
          id: 4,
          path: '/',
          name: '企业文化',
          anchor: 'qiye'
        },
        {
          id: 5,
          path: '/',
          name: '公司环境',
          anchor: 'huanjing'
        },
        // {
        //   id: 6,
        //   path: '/policy',
        //   name: '政策法规'
        // },
        {
          id: 7,
          path: '/about',
          name: '关于我们'
        },
        {
          id: 8,
          path: '/jobs',
          name: '人才理念'
        }
      ]
    }
  },
  computed: {
    ...mapState([
      'navIndex' // 将this.navIndex映射为this.$store.state.navIndex
    ])
  },
  methods: {
    custormAnchor(anchorName) {
      // 找到锚点
      let anchorElement = document.getElementById(anchorName);
      // 如果对应id的锚点存在，就跳转到锚点
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    },
    onClickNavHandler(index) {
      const item = this.navList[index]
      if (item.anchor) {
        // 定位锚点
        this.custormAnchor(item.anchor)
      }
      // 切换导航
      this.switchNav(index)
    },
    ...mapActions([
      'switchNav' // 将this.switchNav()映射为this.$store.dispatch('switchNav')
    ])
  }
};
</script>

<style scoped>
header {
  display: flex;
  height: 41px;
  background: #c6b196;
  justify-content: space-around;
  align-items: center;
}
.nav-tab {
  display: flex;
}
.nav-tab li {
  display: flex;
  height: 41px;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}
.nav-tab li a {
  margin: 0 15px;
  font-size: 12px;
  font-weight: bold;
  color: #333333;
  height: 38px;
  line-height: 38px;
}
.nav-tab li img {
  width: 40px;
  height: 2px;
  margin: 0 auto;
  display: none;
}

.nav-tab li:hover img {
  display: block;
}
.logo {
  width: 75.5px;
  height: 24.5px;
}
</style>