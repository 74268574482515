<template>
  <div class="home">
    <navi-bar></navi-bar>
    <router-view />
    <footer-bar></footer-bar>
  </div>
</template>

<script>
import NaviBar from '@/components/NaviBar'
import HomeList from '@/pages/Home/HomeList'
import FooterBar from '@/components/FooterBar'


export default {
  name: "Home",
  components: {
    NaviBar,
    FooterBar,
    HomeList
  },
  props: {
    temp: String
  },
  data() {
    return {
      title: "中裕康"
    };
  },
  mounted() {
    
  },
  
};
</script>

<style scoped>




</style>
