<template>
  <footer>
    <!-- <p class="company">东莞中裕康医疗科技有限公司</p> -->
    <p class="company">东莞中裕康医疗科技有限公司</p>
    <!-- <p class="company">东莞中裕康医疗科技有限公司</p> -->
    <p class="adress">地址：广东省东莞市南城区碧桂园国际1号楼1308号</p>
    <p class="adress">客服电话：400-1005798</p>
    <p class="icp" @click="onClickICPHandler">ICP备案/许可证号：粤ICP备2022039890号</p>
  </footer>
</template>

<script>
  export default {
    methods: {
      onClickICPHandler() {
        window.open('https://beian.miit.gov.cn/', '_brank')
        // this.$api.user.login()
      }
    }
  }
</script>

<style scoped>
  footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 153px;
    background: #f4f4f4;
  }

  .company {
    font-size: 11px;
    color: #333333;
    margin-bottom: 10px;
  }

  .adress {
    font-size: 9px;
    color: #666666;
    margin-bottom: 10px;
  }

  .icp {
    font-size: 9px;
    color: #999999;
    cursor: pointer;
  }
</style>
